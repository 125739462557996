<template>
  <div>
    <b-card class="m-5">
      <template #header>
        <h1>{{ $t('imprint.label') }}</h1>
      </template>
      <b-card-text>
        <div v-if="language === 'de'">
          <h2>Herausgeber</h2>

          <p>
            Prof. Gerhard Wellein (NHR-Direktor)
          </p>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>

          <h3>Vertreten durch</h3>

          <p>
            Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU)<br/>
            Zentrum für Nationales Hochleistungsrechnen Erlangen (NHR@FAU)<br/>
            c/o Regionales Rechenzentrum Erlangen (RRZE)
          </p>

          <address>
            Martenstraße 1<br/>
            91058 Erlangen
          </address>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>

          <p>
            Gemäß Art. 20, 21 BayHSchG wird die FAU als staatliche Einrichtung und Selbstverwaltungskörperschaft des
            öffentlichen Rechts nach außen durch den Präsidenten vertreten.
          </p>

          <h3>Kontaktperson für den Inhalt</h3>

          <p>Dr. Jan Eitzinger</p>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>

          <h3>Zuständige Aufsichtsbehörde</h3>

          <p>Bayerisches Staatsministerium für Wissenschaft und Kunst</p>

          <address>
            Salvatorstraße 2<br/>
            80327 München
          </address>

          <p>Webseite: <a href="https://www.stmwk.bayern.de">www.stmwk.bayern.de</a></p>

          <h3>Identifikationsnummern</h3>

          <b-table-simple small striped responsive>
            <b-tbody>
              <b-tr>
                <b-th>Umsatzsteuer-Identifikationsnummer (gemäß § 27a Umsatzsteuergesetz)</b-th>
                <b-td>DE 132507686</b-td>
              </b-tr>
              <b-tr>
                <b-th>Steuernummer</b-th>
                <b-td>216/114/20045 (Finanzamt Erlangen)</b-td>
              </b-tr>
              <b-tr>
                <b-th>DUNS-Nummer</b-th>
                <b-td>327958716</b-td>
              </b-tr>
              <b-tr>
                <b-th>EORI-Nummer</b-th>
                <b-td>DE4204891</b-td>
              </b-tr>
              <b-tr>
                <b-th>Bankverbindung</b-th>
                <b-td>Bayerische Landesbank München
                  <ul>
                    <li>SWIFT/BIC-Code: BYLADEMMXXX</li>
                    <li>IBAN: DE66700500000301279280</li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-alert variant="primary" show>
            <h2>Sicherheitsvorfälle</h2>

            <h3>Meldung über Missbrauch von Computer- und Netzwerk-Ressourcen</h3>

            <p>
              Sollte Ihnen irgendeine Form von Missbrauch der Computer- und Netzwerk-Ressourcen an der
              Friedrich-Alexander-Universität Erlangen-Nürnberg auffallen, informieren Sie bitte umgehend
              die für <a href="https://www.rrze.fau.de/abuse/">IT-Sicherheit zuständige Stelle am RRZE</a>.
            </p>
          </b-alert>

          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.copyright variant="light" class="collapse-button">
                  Nutzungsbedingungen
                  <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                  <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
                </b-button>
              </b-card-header>
              <b-collapse id="copyright" accordion="imprint-accordion" role="tabpanel">
                <b-card-body>
                  <h2>Nutzungsbedingungen</h2>

                  <p>
                    Texte, Bilder, Grafiken sowie die Gestaltung dieser Internetseiten können dem Urheberrecht
                    unterliegen.
                  </p>
                  <p>
                    Nicht urheberrechtlich geschützt sind nach § 5 des Urheberrechtsgesetz (UrhG)
                  </p>
                  <ul>
                    <li>
                      Gesetze, Verordnungen, amtliche Erlasse und Bekanntmachungen sowie Entscheidungen und amtlich
                      verfasste Leitsätze zu Entscheidungen und
                    </li>
                    <li>
                      andere amtliche Werke, die im amtlichen Interesse zur allgemeinen Kenntnisnahme veröffentlicht
                      worden sind, mit der Einschränkung, dass die Bestimmungen über Änderungsverbot und Quellenangabe
                      in § 62 Abs. 1 bis 3 und § 63 Abs. 1 und 2 UrhG entsprechend anzuwenden sind.
                    </li>
                  </ul>
                  <p>
                    Als Privatperson dürfen Sie urheberrechtlich geschütztes Material zum privaten und sonstigen
                    eigenen Gebrauch im Rahmen des <a href="http://www.gesetze-im-internet.de/urhg/__53.html">
                    § 53 Urheberrechtsgesetz (UrhG)</a> verwenden. Eine Vervielfältigung oder Verwendung dieser Seiten
                    oder Teilen davon in anderen elektronischen oder gedruckten Publikationen und deren Veröffentlichung
                    ist nur mit unserer Einwilligung gestattet. Diese erteilen auf Anfrage die für den Inhalt
                    Verantwortlichen. Der Nachdruck und die Auswertung von Pressemitteilungen und Reden sind mit
                    Quellenangabe allgemein gestattet.
                  </p>
                  <p>
                    Weiterhin können Texte, Bilder, Grafiken und sonstige Dateien ganz oder teilweise dem Urheberrecht
                    Dritter unterliegen. Auch über das Bestehen möglicher Rechte Dritter geben Ihnen die für den Inhalt
                    Verantwortlichen nähere Auskünfte, sofern diese Rechte nicht bereits durch entsprechende Hinweise
                    im Webangebot kenntlich gemacht sind.
                  </p>
                  <p>
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.exclusions variant="light" class="collapse-button">
                  Haftungsausschluss
                  <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                  <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
                </b-button>
              </b-card-header>
              <b-collapse id="exclusions" accordion="imprint-accordion" role="tabpanel">
                <b-card-body>
                  <h2>Haftungsausschluss</h2>

                  <p>
                    Dieses Impressum gilt nur für die Webauftritte unter den Adressen:<br/>
                    <strong>portal.hpc.fau.de</strong><br/>
                    Für die Inhalte anderer Webangebote sind die jeweiligen Einrichtungen verantwortlich.
                  </p>
                  <p>
                    Alle auf dieser Internetseite bereitgestellten Informationen haben wir nach bestem Wissen und
                    Gewissen erarbeitet und geprüft. Eine Gewähr für die jederzeitige Aktualität, Richtigkeit,
                    Vollständigkeit und Verfügbarkeit der bereit gestellten Informationen können wir allerdings nicht
                    übernehmen. Ein Vertragsverhältnis mit den Nutzern des Internetangebots kommt nicht zustande.
                  </p>
                  <p>
                    Wir haften nicht für Schäden, die durch die Nutzung dieses Internetangebots entstehen. Dieser
                    Haftungsausschluss gilt nicht, soweit die Vorschriften des <a
                    href="http://www.gesetze-im-internet.de/bgb/__839.html">§ 839 des Bürgerlichen Gesetzbuches</a>
                    (Haftung bei Amtspflichtverletzung) einschlägig sind. Für etwaige Schäden, die beim Aufrufen oder
                    Herunterladen von Daten durch Schadsoftware oder der Installation oder Nutzung von Software
                    verursacht werden, wird nicht gehaftet.
                  </p>
                  <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach § 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                    zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                  </p>
                  <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                  </p>
                  <p>
                    Der Betreiber behält es sich ausdrücklich vor, einzelne Webseiten, Web-Dienste oder das gesamte
                    Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung
                    zeitweise oder endgültig einzustellen.
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.disclaimer variant="light" class="collapse-button">
                  Haftung für Links
                  <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                  <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
                </b-button>
              </b-card-header>
              <b-collapse id="disclaimer" accordion="imprint-accordion" role="tabpanel">
                <b-card-body>
                  <h2>Haftung für Links</h2>

                  <p>
                    Unser Angebot enthält Links zu externen Webauftritte Dritter, auf deren Inhalte wir keinen Einfluss
                    haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
                    der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                    verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                  </p>
                  <p>
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                  </p>
                  <p>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                    einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                    Links umgehend entfernen.
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
        <div v-else>
          <b-alert variant="warning" show>
            <strong>Disclaimer:</strong> The translation of this page is only provided for convenience. If there is any
            contradiction between the German and English version, the German language version shall take pre-cedence.
            Any discrepancies or differences created in the translation are not binding and have no legal effect.
          </b-alert>
          <h2>Website owner</h2>

          <p>Prof. Gerhard Wellein (NHR-Direktor)</p>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>

          <h3>Represented by</h3>

          <p>
            Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU)<br/>
            Zentrum für Nationales Hochleistungsrechnen Erlangen (NHR@FAU)<br/>
            c/o Regionales Rechenzentrum Erlangen (RRZE)
          </p>

          <address>
            Martensstraße 1<br/>
            91058 Erlangen<br/>
            Germany
          </address>

          <p>
            According to Art. 20, 21 BayHSchG becomes the FAU as a state institution and self-governing body represented
            by the President under public law.
          </p>

          <h3>Website Contact (Webmaster)</h3>

          <p>Dr. Jan Eitzinger</p>

          <p>
            E-Mail: <a href="hpc-support@fau.de">hpc-support@fau.de</a>
          </p>

          <h3>Responsible controlling authority</h3>

          <p>Bayerisches Staatsministerium für Wissenschaft und Kunst</p>

          <address>
            Salvatorstraße 2<br/>
            80327 München
          </address>

          <p>Website: <a href="https://www.stmwk.bayern.de">www.stmwk.bayern.de</a></p>

          <h3>ID numbers</h3>

          <b-table-simple small striped responsive>
            <b-tbody>
              <b-tr>
                <b-th>VAT identification number</b-th>
                <b-td>DE 132507686</b-td>
              </b-tr>
              <b-tr>
                <b-th>Tax number</b-th>
                <b-td>216/114/20045 (Finanzamt Erlangen)</b-td>
              </b-tr>
              <b-tr>
                <b-th>DUNS number</b-th>
                <b-td>327958716</b-td>
              </b-tr>
              <b-tr>
                <b-th>EORI number</b-th>
                <b-td>DE4204891</b-td>
              </b-tr>
              <b-tr>
                <b-th>Bank details:</b-th>
                <b-td>Bayerische Landesbank München
                  <ul>
                    <li>SWIFT/BIC-Code: BYLADEMMXXX</li>
                    <li>IBAN: DE66700500000301279280</li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <b-alert variant="primary" show>
            <h2>Reporting misuse of computers and network resources</h2>

            <p>
              Should you become aware of any kind of misuse of computers or network resources at FAU, please inform the
              <a href="https://www.rrze.fau.de/abuse/">department responsible for computer security</a> immediately.
            </p>
          </b-alert>

          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.copyright variant="light" class="collapse-button">
                  Copyright
                  <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                  <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
                </b-button>
              </b-card-header>
              <b-collapse id="copyright" accordion="imprint-accordion" role="tabpanel">
                <b-card-body>
                  <h2>Copyright</h2>

                  <p>
                    Unless specified otherwise, all pages of the FAU website are protected by copyright. This applies in
                    particular to texts, images, charts, and files containing style, sound, video, or animation data; it also
                    includes the design of the website.
                  </p>
                  <p>
                    This website uses the official FAU design templates (with some modifications). For usage rights and further
                    information, please see the project website.
                  </p>
                  <p>
                    Reproduction or use of (parts) of the web pages in electronic or printed form and their publication (including
                    on the Internet) is subject to prior approval.
                  </p>
                  <p>
                    Press releases, publications, scripts and information on lectures may be reprinted and evaluated provided that
                    the source is indicated. Furthermore, images, charts, and files containing text or other information, may, in
                    part or in their entirety, be subject to the copyright of third parties.
                  </p>
                  <p>
                    All registered brands and trademarks mentioned on this website and possibly protected by third parties are
                    subject without restriction to the respective applicable trademark law and the property rights of their
                    respective owners. The mere mention of trademarks on this website does not indicate that they are not
                    protected by third party rights.
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.exclusions variant="light" class="collapse-button">
                  Exclusions
                  <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                  <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
                </b-button>
              </b-card-header>
              <b-collapse id="exclusions" accordion="imprint-accordion" role="tabpanel">
                <b-card-body>
                  <h2>Exclusion of liability</h2>

                  <p>
                    This imprint only belongs to the following websites:<br/>
                    <strong>portal.hpc.fau.de</strong><br/>
                    The website owner is not responsible for other websites, that are not listet above.
                  </p>
                  <p>
                    The operator has compiled and verified all information provided with great care. However, we cannot assume
                    liability or furnish a guarantee that the data is correct, complete or up-to-date, or regarding the quality or
                    constant availability of the information provided.
                  </p>
                  <p>
                    We will not be liable for any damages arising from computer viruses or the installation and use of software
                    when accessing or downloading data from this website.
                  </p>
                  <p>
                    Websites credited to an author reflect the opinions and insights of that author.
                  </p>
                  <p>
                    The operator expressly reserves the right to change, amend or delete individual web pages, services or the
                    entire website without prior notice or to interrupt or terminate the publication.
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.disclaimer variant="light" class="collapse-button">
                  Disclaimer
                  <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                  <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
                </b-button>
              </b-card-header>
              <b-collapse id="disclaimer" accordion="imprint-accordion" role="tabpanel">
                <b-card-body>
                  <h2>Links and references (disclaimer)</h2>

                  <p>
                    The operator is only responsible for the original content provided in accordance with the applicable laws.
                    This original content is to be distinguished from links to the websites of other operators. Through these
                    references marked as ‘external links’, the operator enables visitors to access ‘third-party content’.
                  </p>
                  <p>
                    The operator is not responsible for this third-party content as the operator does not initiate the data
                    transmission, does not choose the recipient of the information and does not select or have any influence on
                    the information transmitted. The methods used to provide access and link to this third-party information also
                    do not involve any automatic short-term storage, resulting in a full exclusion of any liability for
                    third-party content on the operator’s part.
                  </p>
                  <p>
                    When links to these websites were first incorporated, however, the authors of the relevant websites or the
                    operator’s editors reviewed the external content to ascertain whether it would possibly entail liability under
                    civil or criminal law. Should the operator become aware or be made aware by others that the content of a
                    website linked from this site could constitute a civil or criminal law violation, then the link will be
                    immediately removed as long as this is technically feasible and within reasonable expectation.
                  </p>
                  <p>
                    Liability for illegal, inaccurate or incomplete content and for damages resulting from the use or non-use of
                    information provided by third parties shall lie exclusively with the respective operators of the linked sites.
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Imprint',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  computed: {
    language () {
      return this.$root.$i18n.locale
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0px;
}
h2 {
  font-size: 1.15rem;
  font-weight: bold;
}
h3 {
  font-size: 1.05rem;
  font-weight: bold;
}
h4 {
  font-size: 1rem;
  font-weight: bold;
}
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.collapse-button {
  text-align: left;
}
</style>
